import React, { Component } from 'react'

import LiensPage from '../containers/LiensPage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const Liens = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentLiens}
    >
      <LiensPage />
    </PrimaryLayout>
  )
}

export default Liens
